import React from "react"
import Layout from "../components/layout"

function Certificates() {
  return (
    <Layout>
      <h1>Certificates</h1>
    </Layout>
  )
}

export default Certificates
